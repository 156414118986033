<template>
  <div class="pdf-display">
    <div v-if="showPDF" v-dragscroll="true" :class="['doc-viewer', { 'zoom-active': scale > 0.9 }]">
      <pdf style="display: none;" :src="base64pdf" @num-pages="pageCount = new Array($event)" />
      <!-- Actual document -->
      <pdf
        v-for="(page, index) in pageCount"
        :key="index"
        :src="src"
        :scale="scale"
        :resize="true"
        :style="{ margin: 'auto', width: `${100 * scale}%` }"
      />
    </div>

    <div class="doc-controls has-text-center">
      <div
        :style="{
          position: 'relative',
          display: 'flex',
          justifyContent: 'space-between',
          height: '60px'
        }"
      >
        <div></div>

        <div
          :style="{
            display: 'flex',
            position: 'absolute',
            top: '10px',
            left: '50%',
            transform: 'translateX(-50%)'
          }"
        >
          <div class="control">
            <b-button @click="zoom(-1)">-</b-button>
          </div>
          <div class="control">
            <b-button @click="zoom(1)">+</b-button>
          </div>
        </div>

        <b-taglist attached>
          <b-tag type="is-light">zoom</b-tag>
          <b-tag type="is-info">{{ (scale * 100).toPrecision(3) }}%</b-tag>
        </b-taglist>
      </div>
    </div>
  </div>
</template>

<script>
/*
	import PDFDisplay from '@/components/PDF/Display'

  <PDFDisplay ref="pdfDisplay" />

  pdfDisplay.open ({
    src: ""
  })
*/

import { dragscroll } from 'vue-dragscroll'
import pdf from 'vue-pdf'

import { methods } from './keys/methods'

export default {
  components: {
    pdf
  },

  data() {
    return {
      isOpen: false,
      src: null,
      scale: 1,
      showPDF: true,
      loading: false,
      pageCount: []
    }
  },

  methods,

  directives: { dragscroll }
}
</script>

<style lang="scss">
.pdf-display {
  .doc-viewer {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 130%;
    min-height: 40vh;
    max-height: calc(100vh - 320px);
    border: 1px solid #dbdbdb;

    &.zoom-active {
      cursor: grab;
    }
  }
}
</style>
