<template>
  <div role="main" data-id="meeting minutes">
    <PDFDisplay
      ref="pdfDisplay"
      :style="{
        maxWidth: '800px',
        position: 'relative',
        margin: '0 auto'
      }"
    />

    <PDFModal ref="pdfModal" v-if="documents.length >= 1" />

    <PDFUploader :update="documentsUpdated" :multiple="false" :pdfOnClick="onPDFClick" />

    <div
      :style="{
        display: 'flex',
        justifyContent: 'right',
        padding: '12px 0'
      }"
    >
      <b-button @click="upload" type="is-primary" :style="{}">
        {{
          hasMinutes && Array.isArray(documents) && documents.length === 1 ? 'Replace' : 'Upload'
        }}
      </b-button>
    </div>
  </div>
</template>

<script>
import PDFUploader from '@/components/Fields/PDFUploader'
import PDFDisplay from '@/components/PDF/Display'
import PDFModal from '@/components/Modals/PDF'

import { methods } from './parts/methods'

export default {
  components: {
    PDFUploader,
    PDFDisplay,
    PDFModal
  },
  methods,

  data() {
    return {
      boardPackageID: null,
      documents: [],
      hasMinutes: false
    }
  },
  mounted() {
    this.refresh()
  }
}
</script>
