/*
  example:
    // note: calls notifyError internally
  
    import { getMF } from '@/services/BoardMeetings/BoardPackage/GetMinutesFile';

    const { doc, successful, message } = await getMF ({
      params: {
        boardPackageID: '',
        asBase64: true
      }
    });
    if (!successful) {
      notifyError(message)
      throw new Error (message)
    }
*/
//
import kms from '@/services/kms'
//
export async function getMF({ params }) {
  let exception = null

  try {
    const result = await kms.get(`/BoardMeetings/BoardPackage/GetMinutesFile`, {
      params
    })

    return {
      successful: true,
      message: '',
      file: result,
      exception
    }
  } catch (x) {
    console.error(exception)
    exception = x
  }

  let message = `The minutes file could not be retrieved.`

  return {
    successful: false,
    message,
    doc: null,
    exception
  }
}

///////
