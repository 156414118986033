/*
  example:
    // note: calls notifyError internally
  
    import { storeMF } from '@/services/BoardMeetings/BoardPackage/StoreMinutesFile';

    const { successful, message } = await storeMF ({
      boardPackageID,
      file
    });
    if (!successful) {
      throw new Error (message)
    }
*/
//
import kms from '@/services/kms'
import { notifyError } from '@/services/notify'

export async function storeMF({ boardPackageID, file }) {
  try {
    const formData = new FormData()
    formData.append('file', file)

    const params = [`boardPackageID=${boardPackageID}`].join('&')

    const path = `/BoardMeetings/BoardPackage/StoreMinutesFile?${params}`
    const result = await kms.post(path, formData, {
      'Content-Type': 'multipart/form-data'
    })

    return {
      successful: true,
      message: '',
      documentID: result.documentId
    }
  } catch (exception) {
    console.error(exception)
  }

  const message = `The minutes file could not be saved.`
  notifyError(message)

  return {
    successful: false,
    message
  }
}

///////
