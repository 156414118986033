import { storeMF } from '@/services/BoardMeetings/BoardPackage/StoreMinutesFile'
import { getMF } from '@/services/BoardMeetings/BoardPackage/GetMinutesFile'
import { getBoardPackage } from '@/services/BoardMeetings/BoardPackage/Get'

import { notifyMessage, notifyProblem } from '@/services/notify'

import _get from 'lodash/get'

export const methods = {
  async refresh() {
    this.loading = true

    this.boardPackageID = parseInt(_get(this, ['$route', 'params', 'id'], ''))

    let boardPackage = null

    await getBoardPackage({
      boardPackageID: this.boardPackageID
    }).then(({ successful, message, boardPackage: boardPackage_ }) => {
      if (!successful) {
        notifyProblem(message)
        throw new Error(message)
      }

      boardPackage = boardPackage_
    })
    if (typeof _get(boardPackage, 'minutesDocumentID', null) !== 'number') {
      this.loading = false
      return
    }

    this.hasMinutes = true

    const { file, successful, message, exception } = await getMF({
      params: {
        boardPackageID: this.boardPackageID,
        asBase64: true
      }
    })
    if (!successful) {
      console.log(exception.status)
      this.loading = false
      throw new Error(message)
    }

    this.documents = [file]

    this.$refs.pdfDisplay.open({
      src: file
    })

    this.loading = false
  },

  documentsUpdated({ documents }) {
    this.documents = documents
  },
  onPDFClick({ pdf }) {
    this.$refs.pdfModal.open({
      src: pdf
    })
  },
  async upload() {
    const documents = this.documents
    const replace = Array.isArray(documents) && documents.length === 1

    const { successful, message } = await storeMF({
      boardPackageID: this.boardPackageID,
      file: this.documents[0]
    })
    if (!successful) {
      throw new Error(message)
    }

    if (replace) {
      notifyMessage(`The minutes document was successfully replaced`)
    } else {
      notifyMessage(message)
    }

    this.refresh()
  }
}
