var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"role":"main","data-id":"meeting minutes"}},[_c('PDFDisplay',{ref:"pdfDisplay",style:({
      maxWidth: '800px',
      position: 'relative',
      margin: '0 auto'
    })}),(_vm.documents.length >= 1)?_c('PDFModal',{ref:"pdfModal"}):_vm._e(),_c('PDFUploader',{attrs:{"update":_vm.documentsUpdated,"multiple":false,"pdfOnClick":_vm.onPDFClick}}),_c('div',{style:({
      display: 'flex',
      justifyContent: 'right',
      padding: '12px 0'
    })},[_c('b-button',{style:({}),attrs:{"type":"is-primary"},on:{"click":_vm.upload}},[_vm._v(" "+_vm._s(_vm.hasMinutes && Array.isArray(_vm.documents) && _vm.documents.length === 1 ? 'Replace' : 'Upload')+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }