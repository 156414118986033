var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pdf-display"},[(_vm.showPDF)?_c('div',{directives:[{name:"dragscroll",rawName:"v-dragscroll",value:(true),expression:"true"}],class:['doc-viewer', { 'zoom-active': _vm.scale > 0.9 }]},[_c('pdf',{staticStyle:{"display":"none"},attrs:{"src":_vm.base64pdf},on:{"num-pages":function($event){_vm.pageCount = new Array($event)}}}),_vm._l((_vm.pageCount),function(page,index){return _c('pdf',{key:index,style:({ margin: 'auto', width: ((100 * _vm.scale) + "%") }),attrs:{"src":_vm.src,"scale":_vm.scale,"resize":true}})})],2):_vm._e(),_c('div',{staticClass:"doc-controls has-text-center"},[_c('div',{style:({
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        height: '60px'
      })},[_c('div'),_c('div',{style:({
          display: 'flex',
          position: 'absolute',
          top: '10px',
          left: '50%',
          transform: 'translateX(-50%)'
        })},[_c('div',{staticClass:"control"},[_c('b-button',{on:{"click":function($event){return _vm.zoom(-1)}}},[_vm._v("-")])],1),_c('div',{staticClass:"control"},[_c('b-button',{on:{"click":function($event){return _vm.zoom(1)}}},[_vm._v("+")])],1)]),_c('b-taglist',{attrs:{"attached":""}},[_c('b-tag',{attrs:{"type":"is-light"}},[_vm._v("zoom")]),_c('b-tag',{attrs:{"type":"is-info"}},[_vm._v(_vm._s((_vm.scale * 100).toPrecision(3))+"%")])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }